import React, { useState, useEffect } from "react";
import { GoShieldCheck } from "react-icons/go";
//import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button, Avatar } from '@nextui-org/react' -minel
import { postProjectsUserDelete } from "../../api/projects";

import { BsKey, BsThreeDotsVertical } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { useParams } from "react-router-dom";

const TeamComponent = ({ member }) => {
  const path = useParams();

  async function delProject() {
    const data = { id: member.userId, slug: path.id };
    const set = await postProjectsUserDelete(data);
  }
  const handleYesButtonClick = () => {
    delProject();
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const images = [
    // "/assets/penaLogos/blue.svg",
    // "/assets/penaLogos/yellow.svg",
    // "/assets/penaLogos/orange.svg",
    // "/assets/penaLogos/purple.svg",
    // "/assets/penaLogos/turquoise.svg",
    // "/assets/penaLogos/yellow.svg",
    "/assets/penaLogos/red.svg",
  ];
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    const randomImage = images[randomIndex];
    setSelectedImage(randomImage);
  }, []);
  return (
    <div className="border justify-center items-center  dark:border-dark-300 w-full  border-gray-200  bg-white  dark:bg-dark-300 h-full mt-6 rounded-md">
      <div className="flex justify-end ">
        <div className="dropdown">
          <div
            tabIndex={0}
            role="button"
            className="p-2 hover:bg-gray-100 m-1 bg-white dark:bg-dark-300"
          >
            <BsThreeDotsVertical size={20} />
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <a>
                <div className="flex">
                  <BsKey size={20} />
                  <p className="font-bold ml-2">İzinler</p>
                </div>
                <p className="ml-6">Yetkileri Değiiştir</p>
              </a>
            </li>

            <li>
              <button className="btn" onClick={handleYesButtonClick}>
                <a>
                  <div className="flex">
                    <RiDeleteBinLine size={20} />
                    <p className="font-bold ml-2">Kaldır</p>
                  </div>
                  <p className="ml-6">Projeden Çıkart</p>
                </a>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center w-full p-5">
        <div className="avatar">
          <div className=" rounded-xl border-[1px] h-16 w-16">
            {/* <img
              src={
                member.uimg !== null
                  ? process.env.NEXT_PUBLIC_USERIMG +
                    member.uimg +
                    "." +
                    member.uimgtype
                  : process.env.NEXT_PUBLIC_USERIMG_BLANK
              }
            /> */}
            {selectedImage && (
              <div className="">
                <img
                  src={selectedImage}
                  alt="Selected"
                  className=" rounded-full"
                  style={{ width: "63px", height: "63px" }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-row items-center">
          <p className="dark:text-gray-400 font-bold text-md mt-2">{`${member.name} ${member.ulname}.`}</p>
          {member.pstatus === 11 && (
            <GoShieldCheck className="text-red-600 dark:text-[#686AC9] text-xl ml-2 mt-2 " />
          )}
        </div>
      </div>
      {/* <Dropdown className="">
          <DropdownTrigger>
            <Button className="bg-white dark:bg-dark-300">
              <BsThreeDotsVertical size={20} />
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions">
            <DropdownItem key="new">
              <div className="flex">
                <BsKey size={20} />
                <p className="font-bold ml-2">İzinler</p>
              </div>
              <p className="ml-6">Yetkileri Değiiştir</p>
            </DropdownItem>
            <DropdownItem key="delete" className="text-danger" color="danger">
              <div className="flex">
                <RiDeleteBinLine size={20} />
                <p className="font-bold ml-2">Kaldır</p>
              </div>
              <p className="ml-6">Projeden Çıkart</p>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="flex flex-col justify-center items-center w-full p-5">
        <Avatar
          src={member.uimg !== null ? process.env.NEXT_PUBLIC_USERIMG + member.uimg + '.' + member.uimgtype : process.env.NEXT_PUBLIC_USERIMG_BLANK}
          className="border-[1px] h-16 w-16"
        /> */}
    </div>
  );
};
export default TeamComponent;
