import React from "react";
import ButtonComponent from "../../../../mainComponents/button";
import { RxHamburgerMenu } from "react-icons/rx";
export default function LandingHeader() {
  return (
    <div>
      <div className="hidden sm:block container mx-auto lg:pt-6 lg:cursor-pointer  ">
        <div className="lg:flex lg:flex-row lg:justify-between lg:gap-8 ">
          <div className="lg:flex lg:gap-4 w-full flex gap-4 mt-5  ">
            <div className="flex overflow-hidden rounded-full">
              <img
                src="../../assets/penalogo.svg"
                className="h-[60px] max-w-[150px] object-center"
              />
            </div>
            <ul className="lg:flex lg:gap-3 lg:mt-5 lg:ml-2 flex gap-5 mt-5 lg:w-full">
              <li className="lg:w-[100px] lg:h-[32px] lg:text-xl lg:hover:leading-4 lg:hover:border-b-2 lg:border-red-600 lg:transition-all lg:duration-250 w-[50px] h-[16px] text-l  ">
                HeyPena
              </li>
              <li className="lg:w-[100px] lg:h-[32px] lg:text-xl lg:hover:leading-4 lg:hover:border-b-2 lg:border-red-600 lg:transition-all lg:duration-250 w-[50px] h-[16px] text-l ">
                Özellikler
              </li>
              <li className="lg:w-[100px] lg:h-[32px] lg:text-xl lg:hover:leading-4 lg:hover:border-b-2 lg:border-red-600 lg:transition-all lg:duration-250  w-[50px] h-[16px] text-l ">
                Araçlar
              </li>
              <li className="lg:w-[100px]  lg:h-[32px] lg:text-xl lg:hover:leading-4 lg:hover:border-b-2 lg:border-red-600 lg:transition-all lg:duration-250  w-[50px] h-[16px] text-l ">
                Destek
              </li>
            </ul>
          </div>
          <ButtonComponent
            id={""}
            href="/login "
            title={"Giriş Yap"}
            bgcolor="bg-red-33 mt-4 min-h-14 rounded-3xl  text-center  text-white font-semibold w-[100px] lg:block hidden mx-auto"
          />
        </div>
        <ButtonComponent
          id={""}
          href="/login "
          title={"Giriş Yap"}
          bgcolor="lg:bg-red-33 lg:mt-4 lg:rounded-3xl lg:text-white lg:font-semibold lg:w-full lg:hidden block bg-red-300 mt-4 rounded-3xl text-white font-semibold w-[200px] mx-auto"
        />
      </div>
      <div className="flex justify-between sm:hidden mx-5 mt-3">
        <div className="col-span-1 text-logo-blue flex items-center justify-between">
          <img
            src="../../assets/penalogo.svg"
            className="h-[60px] max-w-[150px] object-center"
          />
        </div>
        <div className="dropdown dropdown-end">
          <div tabIndex={0} role="button" className="btn m-1">
            <RxHamburgerMenu />{" "}
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <a>Item 1</a>
            </li>
            <li>
              <a>Item 2</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
