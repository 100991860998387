import TaskComponent from '../components/TaskComponent'
import HeaderComponent from 'mainComponents/headerComponent'
import MenuBarComponent from '../components/MenuBarComponent'
import {getProjectsTasks} from "../api/projects"
import { useEffect,useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function ProjectsTasks() {
  const { id } = useParams();
  const [getTasks, setTasks] = useState({});
  const [ready,setReady] = useState(false);
  const activePanel = useSelector((state) => state.popupState);

  async function fetchData() {
    const data = { slug : id}
    const get = await getProjectsTasks(data);

    setTasks(get)
    setReady(true)
}
  useEffect(() => {
      fetchData();
  }, [activePanel.activePanel]);

  return ready &&(
    <div className="w-full h-auto pb-5 mt-20 transition duration-300 dark:bg-dark-100 bg-stone-100 lg:mt-0">
      <div className="container mx-auto">
        <HeaderComponent title={'Görev Listesi'} />
        <MenuBarComponent title={'tasks'} />
        <TaskComponent data={getTasks.projectTasks} />
      </div>
    </div>
  )
}
