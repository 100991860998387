import React from 'react'

export default function LandingMainD() {
  return (
    <div className="container mx-auto">
      <div className="flex lg:flex-row flex-col-reverse lg:justify-between ">
        <div className="p-4 lg:w-2/3 w-full">
          <div className="text-[48px] font-semibold text-gray-44">Hey Pena Nedir?</div>
          <div className="max-w-[600px]">
            <div className="font-normal text-gray-44 text-[20px] ">
              Hey Pena, ekiplere ve bireylere projelerini kolaylıkla planlama, organize etme ve yürütme yetkisi veren son teknoloji bir çevrimiçi platformdur.
              <br />
              <br />
              Kullanıcı dostu bir arayüzü, her boyuttaki veya karmaşıklıktaki projelere uyacak şekilde uyarlanabilen güçlü özellikler ve araçlarla birleştiren, tüm proje yönetimi
              ihtiyaçlarınız için tek adrestir.
            </div>
          </div>
        </div>
        <div className="lg:w-1/3 w-full self-center">
          <img src="../../assets/landing/manpho.png" alt="LandingMainC" className="w-full" />
        </div>
      </div>
    </div>
  )
}
