import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { VscCircleFilled } from "react-icons/vsc";
import { FiUsers } from "react-icons/fi";
import { CiClock2 } from "react-icons/ci";
import { BsPatchExclamation, BsFileCheck } from "react-icons/bs";
import "suneditor/dist/css/suneditor.min.css";
import Notiflix from "notiflix";
import { useDispatch } from "react-redux";
import { setActivePanel } from "../../../store/popupState";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {
  getProjectsTeam,
  postProjectsTaskNew,
  postProjectTask,
} from "../../../services/projects";

const SunEditor = React.lazy(() => import("suneditor-react"), {
  ssr: false,
});

export default function ProjectTaskComponent(props) {
  const path = useParams();

  useEffect(() => {
    if (path.id !== undefined) {
      getTeam(path.id);
    }
  }, [path.id]);

  //componentType == 0 => Oluştur, componentType == 1 => Düzenle, componentType == 2 => Göster
  const dispatch = useDispatch();
  const [componentType, setComponentType] = useState(props.type);
  const [menuSelected, setMenuSelected] = useState(0);
  const [colorSelected, setColorSelected] = useState(3);
  const [colorColor, setColorColor] = useState("text-red-600");
  const [colorText, setColorText] = useState("ÖNCELİK");
  const [imready, setImReady] = useState(false);
  //After set

  const [team, setTeam] = useState([]);
  const [curMembers, setCurMembers] = useState([]);
  const [curMembersLabel, setCurMembersLabel] = useState([]);
  const [t_content, setTaskContent] = useState("");
  const [CanEdit, setCanEdit] = useState(false);
  const [TaskId, setTaskId] = useState(0);
  const [TaskTitle, setTaskTitle] = useState("");
  const [Createdat, setCreatedat] = useState("");
  const [TaskStatus, setTaskStatus] = useState(0);
  const [CurSureText, setCurSurecText] = useState("");
  const [CurUserText, setCurUserText] = useState("");
  const [TaskFinished, setTaskFinished] = useState("");
  const [TaskPriority, setTaskPriority] = useState("");
  const curMemberIds = curMembers;
  const list = [
    { value: 0, label: "Yeni" },
    { value: 1, label: "İşlemde" },
    { value: 2, label: "Hazır" },
    { value: 3, label: "İncelemede" },
    { value: 4, label: "Tamamlandı" },
  ];

  //Görev için projedeki tüm ekibi getir
  async function getTeam(id) {
    const data = { slug: id };
    const team = await getProjectsTeam(data);
    var options = [];
    {
      team.usersData !== null &&
        team.usersData.map((data, key) => {
          options.push({
            value: data.userId,
            label: data.name + " " + data.ulname,
          });
        });
    }
    setTeam(options);
  }

  //Yeni görev ekle veya görevi düzenle
  async function setNewTaskContent() {
    if (TaskTitle == "") return;
    
    if (componentType == 0) {
      const data = {
        tsubject: TaskTitle,
        tcontent: t_content,
        tpriority: colorSelected,
        curUsers: curMembers,
        tstatus: TaskStatus,
      };
      const set = await postProjectsTaskNew(path.id, data);
      dispatch(setActivePanel([0, 0]));
    } else if (componentType == 1) {
      const data = {
        taskid: TaskId,
        tsubject: TaskTitle,
        tcontent: t_content,
        tpriority: colorSelected,
        curUsers: curMembers,
        tstatus: TaskStatus,
      };
      const set = await postProjectsTaskNew(path.id, data);
      dispatch(setActivePanel([0, 0]));
    }
  }

  //Varolan bir görevi getir
  async function getTaskContent() {
    Notiflix.Block.pulse(".right-panel");
    setTimeout(async function () {
      const data = { taskid: props.type };
      const agla = {
        slug: path.id,
        taskId: data.taskid,
      };
      const get = await postProjectTask(agla.slug, agla.taskId);

      if (get) {
        var taskinfo = get.projectTask[0];
        var userinfo = get.userinfo;
        if (get.canedit == true) setCanEdit(true);
        var getcontent = "";
        try {
          getcontent = JSON.parse(taskinfo.tcontent);
        } catch (e) {
          getcontent = taskinfo.tcontent;
        }
        console.log(get)
        setTaskId(taskinfo.id);
        setTaskTitle(taskinfo.tsubject);
        setTaskContent(getcontent);
        setCreatedat(taskinfo.created_at);
        setTaskStatus(taskinfo.tstatus);
        // setCurSurecText(t_surec[taskinfo['tstatus']])
        if (userinfo) {
          setCurUserText(userinfo.name + " " + userinfo.ulname);
        }

        if (taskinfo.finished_comment) {
          setTaskFinished(taskinfo.finished_comment);
        }
        setColorSelected(taskinfo.priority);
        setImReady(true);
        Notiflix.Block.remove(".right-panel", 200);
      }
    }, 200);
  }

  React.useEffect(() => {
    if (componentType == 0) {
      setImReady(true);
      return;
    }
    if (componentType == 2) return;
    getTaskContent();
  }, []);

  const handleColor = (opt, title, color) => {
    if (componentType == 2) return;
    setColorSelected(opt);
    setColorText(title);
    setColorColor("text-[" + color + "]");
  };

  const handleOptions = (opt) => {
    if (componentType == 2) return;
    if (opt == menuSelected) opt = 0;
    setMenuSelected(opt);
  };
  return (
    <div className="absolute z-50 w-full h-full">
      <div
        onClick={() => dispatch(setActivePanel([0, 0]))}
        className="bg-[#232326] opacity-80 transition-all w-full h-full fixed bottom-0 right-0 left-0 top-0 z-50"
      ></div>
      <div className="py-5 px-5 bg-white w-[420px] dark:bg-dark-100 top-0 right-0 transition-all h-full fixed z-50 space-y-4 right-panel">
        <div className="flex items-center justify-between space-x-12">
          <h1 className="text-lg font-normal opacity-60">Görev Detayları</h1>
          <button
            onClick={() => dispatch(setActivePanel([0, 0]))}
            className="justify-end p-2 rounded-full opacity-20 hover:bg-stone-300"
          >
            <FaTimes size={23} />
          </button>
        </div>

        <h3 className="text-xs font-bold text-red-600">İÇERİK</h3>

        <div className="w-full">
          <input
            type="text"
            defaultValue={TaskTitle}
            onChange={(e) => setTaskTitle(e.target.value)}
            placeholder="Başlık Ekleyin"
            className="w-full max-w-xs input input-bordered"
            style={{ backgroundColor: "#fafafa", borderRadius: "0px" }}
            isClearable
          />
        </div>

        {imready && (
          <p id="editorjs" style={{ marginTop: 10 }}>
            <SunEditor
              imageUploadHandler={() => {}}
              onImageUploadBefore={() => {}}
              onVideoUpload={() => {}}
              onVideoUploadBefore={() => {}}
              onAudioUpload={() => {}}
              onAudioUploadBefore={() => {}}
              onChange={(text) => setTaskContent(text)}
              defaultValue={t_content}
              readOnly={componentType == 2 ? true : false}
              setOptions={{
                mode: "balloon",
                minHeight: "200px",
                buttonList: [["fullScreen"]],
              }}
            />
          </p>
        )}

        <div className="flex px-3 py-3 border-dashed border-y border-zinc-200">
          <div
            className="w-full text-center cursor-pointer"
            onClick={() => handleOptions(1)}
          >
            <BsPatchExclamation className="mx-auto" size={23} color="#dc2626" />
            <h3 className="text-[11px] mt-2 font-medium text-black">ÖNCELİK</h3>
          </div>

          <div
            className="w-full text-center cursor-pointer"
            onClick={() => handleOptions(2)}
          >
            <FiUsers className="mx-auto" size={20} color="#dc2626" />
            <h3 className="text-[11px] mt-3 font-medium text-black">HEDEF</h3>
          </div>

          <div
            className="w-full text-center cursor-pointer"
            onClick={() => handleOptions(3)}
          >
            <CiClock2
              className="mx-auto"
              size={25}
              fill="#dc2626"
              color="#dc2626"
            />
            <h3 className="text-[11px] mt-2 font-medium text-black">TESLİM</h3>
          </div>

          <div
            className="w-full text-center cursor-pointer"
            onClick={() => handleOptions(4)}
          >
            <BsFileCheck className="mx-auto" size={23} color="#dc2626" />
            <h3 className="text-[11px] mt-3 font-medium text-black">DURUM</h3>
          </div>
        </div>

        <div id="mainarea">
          {menuSelected == 1 && (
            <div className="flex border-b border-dashed border-zinc-200">
              <h3
                className={`w-1/5 text-center self-center text-xs font-medium ${colorColor}`}
              >
                {colorText}
              </h3>
              <div className="w-4/5 flex items-center content-center min-h-[80px] px-5">
                <VscCircleFilled
                  onClick={() => handleColor(1, "Çok Düşük", "#cddc39")}
                  className="cursor-pointer rounded-full text-[60px] hover:text-[70px] transition-all duration-200"
                  color={
                    colorSelected == 0 || colorSelected == 1
                      ? "#cddc39"
                      : "#ccc"
                  }
                />
                <VscCircleFilled
                  onClick={() => handleColor(2, "Düşük", "#8bc34a")}
                  className="cursor-pointer rounded-full text-[60px] hover:text-[70px] transition-all duration-200"
                  color={
                    colorSelected == 0 || colorSelected == 2
                      ? "#8bc34a"
                      : "#ccc"
                  }
                />
                <VscCircleFilled
                  onClick={() => handleColor(3, "Normal", "#039be5")}
                  className="cursor-pointer rounded-full text-[60px] hover:text-[70px] transition-all duration-200"
                  color={
                    colorSelected == 0 || colorSelected == 3
                      ? "#039be5"
                      : "#ccc"
                  }
                />
                <VscCircleFilled
                  onClick={() => handleColor(4, "Yüksek", "#ff9800")}
                  className="cursor-pointer rounded-full text-[60px] hover:text-[70px] transition-all duration-200"
                  color={
                    colorSelected == 0 || colorSelected == 4
                      ? "#ff9800"
                      : "#ccc"
                  }
                />
                <VscCircleFilled
                  onClick={() => handleColor(5, "Çok Yüksek", "#da402f")}
                  className="cursor-pointer rounded-full text-[60px] hover:text-[70px] transition-all duration-200"
                  color={
                    colorSelected == 0 || colorSelected == 5
                      ? "#da402f"
                      : "#ccc"
                  }
                />
              </div>
            </div>
          )}

          {menuSelected === 2 && (
            // <div className="flex flex-row border-b border-dashed border-zinc-200 ">
            //   {team.map((member) => (
            //     <div key={member.id} className="w-full text-center">
            //       <h3 className="text-[11px] mt-2 font-medium text-black flex flex-col">{`${member.ufname} ${member.ulname}`}</h3>
            //     </div>
            //   ))}
            // </div>

            <div className="task-group">
              <div className="columns">
                <div
                  className="column"
                  style={{ maxWidth: 100, paddingTop: 16 }}
                >
                  <h3>
                    <span>Hedef</span>
                  </h3>
                </div>
                <div className="column">
                  <div className="field">
                    {team !== null && (
                      <Select
                        placeholder="Seçiniz"
                        defaultValue={curMembersLabel || "Select"}
                        // onChange={(choice) => setCurMembers(choice)}
                        onChange={(choice) => {
                          const selectedIds = choice.map(
                            (member) => member.value
                          );
                          const selectedLabel = choice.map((member) => member);

                          setCurMembers(selectedIds);
                          setCurMembersLabel(selectedLabel);
                        }}
                        closeMenuOnSelect={false}
                        isMulti
                        options={team}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {menuSelected == 3 && (
            <div className="flex border-b border-dashed border-zinc-200">
              <h3 className="w-1/5 text-center self-center text-xs font-medium">
                Teslim
              </h3>
              <div className="w-4/5 flex items-center content-center min-h-[80px] px-5">
                <input
                  type="date"
                  className="w-full input input-bordered"
                  style={{ backgroundColor: "#fafafa", borderRadius: "0px" }}
                />
              </div>
            </div>
          )}

          {menuSelected == 4 && (
            <Select
              className="w-full mb-5"
              label="Durum Seçiniz"
              placeholder="Güncel Durum"
              options={list}
              onChange={(choice) => setTaskStatus(choice.value)}
              defaultValue={list.filter((item) => item.value === TaskStatus)}
              closeMenuOnSelect={true}
            />
          )}
        </div>

        {(componentType == 0 || componentType == 1) && (
          <div>
            <button
              onClick={() => {
                setNewTaskContent();
              }}
              className="btn bg-[#da402f] text-white text-md w-full"
              variant="solid"
            >
              Gönder
            </button>
          </div>
        )}
        <div className="w-full border-t border-dashed opacity-50 border-zinc-300"></div>
      </div>
    </div>
  );
}
