import React from 'react'
import ActiveComponentInsıde from './inside.js'
import { Link } from 'react-router-dom'


const ActivComponent = ({ title, darkImage, lightImage, desc, data, isProject }) => {
  return (
    <div className=" border dark:border-dark-300 w-full border-gray-200  bg-white  dark:bg-dark-300  my-3  p-[5px] min-h-[400px] ">
      <div className="flex justify-between">
        <h1 className=" m-4 w-full text-black dark:text-darkWhite-300 text-[14px] font-bold">{title}</h1>
        <div className=" underline underline-offset-8 decoration-dotted m-4 text-red-600 dark:text-darkPurple  text-[13px] font-semibold whitespace-nowrap">
          <Link to={isProject ? '/projects' : '/calendar'}>Tümünü Gör</Link>
        </div>
      </div>
      {data?.length > 0 ? (
        data
          .slice(0, 5)
          .map((item, index) => (
            <div key={index}>
              {isProject ? <ActiveComponentInsıde item={item } isProject={isProject} /> : <ActiveComponentInsıde item={item} isProject={isProject} />}
            </div>
          ))
      ) : (
        <div className="flex flex-col items-center  ">
          <img src={lightImage} className="dark:hidden lg:w-2/3 w-[50vw] h-[200px] " alt="" />
          <img src={darkImage} className="hidden dark:block lg:w-2/3 w-[50vw] h-[200px] " alt="" />

          <p className="opacity-50 text-center w-[300px] text-sm">{desc}</p>
        </div>
      )}
    </div>
  )
}
export default ActivComponent
