import React from 'react'

export default function LandingMainC() {
  return (
    <div className="grid lg:grid-cols-4 grid-cols-2">
      <div className="col-span-2">
        <img src="../../assets/landing/penatel.png" alt="LandingMainC" className="w-full" />
      </div>
      <div className="col-span-2 mx-auto lg:my-[20%] my-5 ">
        <div className="max-w-md">
          <div className="font-bold text-[#424242] text-[16px] mb-1">Basit ve anlaşılır arayüz</div>
          <div className="font-semibold text-gray-44 text-[20px] ">Pena basit ve kullanıcı dostu arayüzü ile aradığınız her şeyi kolayca bulmanızda yardımcı olur.</div>
        </div>
        <div className="max-w-md mt-9">
          <div className="font-bold text-gray-33 text-[16px] mb-1">Etkili Proje Yönetim Sistemi</div>
          <div className="font-semibold text-gray-44 text-[20px]">Projelerinizi oluşturun, ekibinizi dahil edin ve görevler ekleyin. İşte hepsi bu kadar basit!</div>
        </div>
      </div>
    </div>
  )
}
