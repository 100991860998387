import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { Avatar } from "@nextui-org/react";
import CircularProgressComponent from "mainComponents/circularProgressComponent";

const GorevlerCardComponent = (props) => {
  const {
    title,
    description,
    date,
    priority,
    isPrivate,
    status,
    member,
    onClick,
  } = props;

  const getPriotityText = (priority) => {
    switch (priority) {
      case 1:
        return "Çok Düşük";
      case 2:
        return "Düşük";
      case 3:
        return "Normal";
      case 4:
        return "Yüksek";
      case 5:
        return "Çok Yüksek";
      default:
        return "";
    }
  };

  const priorityText = getPriotityText(priority);

  return (
    <div
      className={`relative hover:translate-y-[-5px] cursor-pointer transition-all hover:duration-500 hover:shadow-md rounded-[6px] w-full h-[200px] ${
        status === "Tamamlandı"
          ? "bg-[#ECECEC] dark:bg-[#1D1D1D]"
          : "dark:bg-dark-300 bg-white"
      }`}
      onClick={isPrivate === false && onClick}
    >
      <h2 className="text-base font-semibold ml-8 pt-5">{title}</h2>

      {isPrivate ? (
        priorityText && (
          <p className="ml-8 text-xs font-light text-[#999] mt-1">
            Öncelik: {priorityText}
          </p>
        )
      ) : (
        <p className="ml-8 text-xs font-light text-[#999] mt-1">{status}</p>
      )}
      <div className="absolute left-4 bottom-5 ml-4 gap-3 flex flex-col items-start">
        {isPrivate === false && (
          <div
            className={`flex flex-row items-center gap-2 ${
              isPrivate ? "hidden" : "block"
            }`}
          >
            <div className="avatar">
              <div className="w-24 rounded-full">
                <img
                  src={
                    member.member_img !== null
                      ? process.env.NEXT_PUBLIC_USERIMG +
                        member.member_img +
                        "." +
                        member.member_imgtype
                      : process.env.NEXT_PUBLIC_USERIMG_BLANK
                  }
                />
              </div>
            </div>
            <p>{member.member_name}</p>
          </div>
        )}

        <div className="flex flex-row items-center">
          <FaCalendarAlt className="text-gray-600 dark:text-gray-400 text-sm" />
          <p className="ml-2 text-xs text-gray-500 dark:text-gray-400 ">
            {date}
          </p>
        </div>
      </div>

      <div className="absolute bottom-6 right-4 flex items-center gap-2 flex-col">
        <CircularProgressComponent
          items={{ priority, width: "40px", height: "40px" }}
        />
        {isPrivate === false && (
          <p className="text-xs font-light text-[#999] mt-1">{priorityText}</p>
        )}
      </div>
    </div>
  );
};

export default GorevlerCardComponent;
