'use client'
import React from 'react'
import GorevComponent from './gorevComponent'


const AsideComponent = ({ title, darkImage, lightImage, desc1, titlepos, tasks }) => {
  return (
    <div className=" border dark:border-dark-300 border-gray-200  bg-white dark:bg-dark-300  md:ml-5 md:mt-3   p-[5px] min-h-[400px]">
      {titlepos == 'top' && (
        <div className="flex  justify-between mt-3">
          <h1 className=" m-4 w-[205px] dark:text-darkWhite-300  text-[#283252] text-sm font-bold ml-4">{title}</h1>
          <div className=" underline underline-offset-8 decoration-dotted m-4 text-red-600 dark:text-darkPurple  text-[13px] font-semibold whitespace-nowrap">
            <a href="/tasks">Tümünü Gör</a>
          </div>
        </div>
      )}

      <div className={`flex flex-col  justify-center ${titlepos == 'top' && tasks != null && tasks.length > 0 ? 'items-star mx-4' : 'items-center'} `}>
        <img src={lightImage} className={` md:w-2/3 w-[50vw] h-[200px] ${titlepos == 'top' && tasks != null && tasks.length > 0 ? 'hidden' : 'block dark:hidden'}`} alt="" />
        <img src={darkImage} className={` md:w-2/3 w-[50vw] h-[200px]  p-8 ${titlepos == 'top' && tasks != null && tasks.length > 0 ? 'hidden' : 'hidden dark:block'}`} alt="" />
        {titlepos == 'top' && tasks != null && tasks.length > 0 && (
          <div>
            {tasks.slice(0,5).map((task, index) => (
              <GorevComponent key={index} task={task.tsubject} priority={task.tpriority} />
            ))}
          </div>
        )}
        {titlepos == 'bottom' && (
          <div className="flex flex-col justify-center items-center">
            <h1 className="ml-auto m-4 w-full dark:text-darkWhite-300  text-black text-[15px] font-bold flex justify-center">{title}</h1>
            <p className=" opacity-50 text-center text-sm ">{desc1}</p>
            <div className="flex w-80 justify-center m-3">
              <button radius="sm" className=" btn text-white text-sm dark:bg-darkViolet bg-red-500 hover:bg-violet-600  p-2">
                Premium&apos;a Yükselt
              </button> 
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default AsideComponent
