import React from "react";
import { Link } from "react-router-dom";
import { PiPenNibLight } from "react-icons/pi";
import { CiClock2 } from "react-icons/ci";
import ButtonComponent from "mainComponents/button";
import { RiComputerLine } from "react-icons/ri";
import { AiOutlineDelete } from "react-icons/ai";
import { PiNotePencilLight } from "react-icons/pi";
import { CiGrid41 } from "react-icons/ci";
import { GoCalendar } from "react-icons/go";
import { FiUsers } from "react-icons/fi";
import { FiDroplet } from "react-icons/fi";
import { useState } from "react";
import RequestPopupComponent from "mainComponents/Sidebar/RequestPopup";
import ThemePopupComponent from "mainComponents/Sidebar/ThemePopup";
import EditPopupComponent from "mainComponents/Sidebar/EditPopup";
import DeletePopupComponent from "mainComponents/Sidebar/deletepopup";

const PanelHpComponent = ({
  projectData,
  timelineData,
  usersData,
  isAdmin,
}) => {
  const [isRequestPopupOpen, setIsRequestPopupOpen] = useState(false);
  const [isThemePopupOpen, setIsThemePopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const openRequestPopup = () => {
    setIsRequestPopupOpen(true);
  };

  const openThemePopup = () => {
    setIsThemePopupOpen(true);
  };

  const openEditPopup = () => {
    setIsEditPopupOpen(true);
  };
  const openDeletePopup = () => {
    setIsDeletePopupOpen(true);
  };

  const closePopups = () => {
    setIsRequestPopupOpen(false);
    setIsThemePopupOpen(false);
    setIsEditPopupOpen(false);
    setIsDeletePopupOpen(false);
  };

  return (
    <div className="border dark:border-dark-300 w-full border-gray-200  bg-white  dark:bg-dark-300  my-3  p-[5px] rounded-md">
      <div className="flex flex-col h-[550px]">
        <div className="p-4 flex justify-between">
          <div>
            <h1 className="w-[200px] text-black dark:text-darkWhite-300 text-xl font-semibold">
              {projectData.pname}
            </h1>
            <p className="opacity-50  text-md ">{projectData.pfirm}</p>
          </div>
          <div className="flex space-x-2 mt-1">
            {isAdmin && (
              <React.Fragment>
                <ButtonComponent
                  onClick={openRequestPopup}
                  to={"/"}
                  bgcolor={"bg-white text-black hover:bg-gray-100"}
                  icon={<FiUsers size={16} color="#1e293b" />}
                />
                <ButtonComponent
                  onClick={openThemePopup}
                  to={"/"}
                  bgcolor={"bg-white hover:bg-gray-100"}
                  icon={<RiComputerLine size={16} color="#1e293b" />}
                />
                <ButtonComponent
                  onClick={openEditPopup}
                  to={"/"}
                  bgcolor={"bg-pink-200 hover:bg-pink-100"}
                  icon={<PiNotePencilLight size={16} color="#b91c1c" />}
                />
              </React.Fragment>
            )}
            <ButtonComponent
              onClick={openDeletePopup}
              to={"/"}
              bgcolor={"bg-[#da402f] hover:bg-[#df5142]"}
              icon={<AiOutlineDelete size={16} color="#fff" />}
            />
          </div>
        </div>

        <div className="m-4 flex">
          <p className="w-full opacity-50 text-md">{projectData.pdesc}</p>
          <div className="flex flex-row justify-between">
            <img
              src="../../assets/heypena.png"
              alt=""
              className="h-[40px] w-[40px] rounded-full object-center border-3 bg-stone-100"
            />
           
            {usersData.slice(0, 5).map((item, index) => (<div></div>
              // <Link
              //   to="/projects/crew"
              //   className="flex dark:text-dark-300 -ml-3"
              // >
              //   <div className="avatar items-center justify-center ml-3">
              //     <div className="w-7 h-7 rounded-full  border-[1px]">
              //       <img
              //         src={
              //           item.uimg !== null
              //             ? process.env.NEXT_PUBLIC_USERIMG +
              //               item.uimg +
              //               "." +
              //               item.uimgtype
              //             : process.env.NEXT_PUBLIC_USERIMG_BLANK
              //         }
              //       />
              //       {/* fotoğraf denemek için kullanıcalbilr https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg */}
              //     </div>
              //   </div>
              // </Link>
            ))}
          </div>
        </div>

        <div className="w-full border-t border-zinc-300 "></div>
        <div className="lg:flex lg:justify-between p-4 mr-8 grid grid-cols-2 gap-5 mx-10">
          <div className="">
            <PiPenNibLight
              className="fill-[#dc2626] dark:fill-[#797BF2]"
              size={25}
            />
            <h2 className="mt-3 text-black dark:text-darkWhite-300 text-xs font-semibold">
              Proje Alanı
            </h2>
            <p className="text-xs opacity-50">Yazılım</p>
          </div>
          <div>
            <CiClock2
              className="fill-[#dc2626] dark:fill-[#797BF2]"
              size={25}
            />
            <h2 className="mt-3 text-black dark:text-darkWhite-300 text-xs font-semibold">
              Oluşturulma Tarihi
            </h2>
            <p className="text-xs opacity-50">{projectData.created_at}</p>
          </div>
          <div>
            <GoCalendar
              className="fill-[#dc2626] dark:fill-[#797BF2]"
              size={25}
            />
            <h2 className="mt-3 text-black dark:text-darkWhite-300 text-xs font-semibold">
              Tahmini Bitiş Tarihi
            </h2>
            <p className="text-xs opacity-50">{projectData.penddate}</p>
          </div>
          <div>
            <CiGrid41
              className="fill-[#dc2626] dark:fill-[#797BF2]"
              size={25}
            />
            <h2 className="mt-3 text-black dark:text-darkWhite-300 text-xs font-semibold">
              Proje Bütçesi
            </h2>
            <p className="text-xs opacity-50">{projectData.pbudget}</p>
          </div>
        </div>

        <div className="w-full border-t border-zinc-300 p-3 "></div>
        <div className="p-6 text-xs">
          <h2 className=" text-red-600 dark:text-violet-300 text-[11px] font-bold">
            SON AKTİVİTELER
          </h2>
          <div className="mt-6 flex ">
            <FiDroplet
              color="#5eead4"
              className="mr-3 bg-teal-100 text-4xl rounded-full p-3 "
            />
            Minnak tarafından proje oluşturuldu.
          </div>
          <p className="text-[10px] ml-12">{projectData.created_at}</p>
        </div>
        {isRequestPopupOpen && (
          <RequestPopupComponent
            closePopup={setIsRequestPopupOpen}
            show={isRequestPopupOpen}
          />
        )}
        {isThemePopupOpen && (
          <ThemePopupComponent
            closePopup={setIsThemePopupOpen}
            show={isThemePopupOpen}
          />
        )}
        {isEditPopupOpen && (
          <EditPopupComponent
            closePopup={setIsEditPopupOpen}
            show={isEditPopupOpen}
            projectsData={projectData}
          />
        )}
        {isDeletePopupOpen && (
          <DeletePopupComponent
            closePopup={() => setIsDeletePopupOpen(false)}
          />
        )}
      </div>
    </div>
  );
};
export default PanelHpComponent;
