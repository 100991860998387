import HeaderComponent from "mainComponents/headerComponent";
import MenuBarComponent from "../components/MenuBarComponent";
import TeamComponent from "../components/TeamComponent";
import { getProjectsTeam } from "../api/projects";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export default function ProjectsTeam() {
  const { id } = useParams();
  const [teamData, setTeamData] = useState([]);

  async function fetchTeamData() {
    const response = await getProjectsTeam({ slug: id });
    setTeamData(response.usersData);
  }

  useEffect(() => {
    fetchTeamData();
  }, []);
  return (
    <div className="w-full dark:bg-dark-100 lg:h-screen h-auto bg-stone-100 transition duration-300 lg:py-0 py-10 lg:mt-0 mt-20">
      <div className="container mx-auto">
        <HeaderComponent title={"Ekip Listesi"} />
        <MenuBarComponent title={"team"} />
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
          {teamData.length > 0 &&
            teamData.map((member, index) => (
              <TeamComponent key={index} member={member} />
            ))}
        </div>
      </div>
    </div>
  );
}
