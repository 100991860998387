import { ProCreateComponent } from 'mainComponents/proCreateComponent'
import React from 'react'
import HeaderComponent from 'mainComponents/headerComponent'

export default function Finish() {
  return (
    <div className=" w-full h-screen dark:bg-dark-100  bg-stone-100 ">
      <div className="container mx-auto">
        <HeaderComponent title={'Adım 1: Proje Bilgisi'} />
        <ProCreateComponent />
      </div>
    </div>
  )
}
