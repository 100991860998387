import React, { useEffect, useState } from "react";
import HeaderComponent from "mainComponents/headerComponent";
import PanelHpComponent from "./components/PanelHpComponent";
import PAsideComponent from "./components/PAsideComponent";
import MenuBarComponent from "./components/MenuBarComponent";
import PAside2Component from "./components/PAsideTwoComponent";
import { getProjectsDash, getProjectsTasks } from "./api/projects";
import { useParams } from "react-router-dom";


export default function ProjectsDashboard() {
  const { id } = useParams();
  const data = { slug: id };
  const [getTasks, setTasks] = useState([]);
  const [totalTasksCount, setTotalTasksCount] = useState([]);
  const [ongoingTasksCount, setOngoingTasksCount] = useState([]);
  const [completedTasksCount, setCompletedTasksCount] = useState([]);
  const [ProjectsDashData, setProjectsDashData] = useState({});
  const [pageReady, setReady] = useState(false);

  async function fetchData() {
    const get = await getProjectsDash(data);
    setProjectsDashData(get);
    const task = await getProjectsTasks(data);
    setTasks(task)
    setReady(true);

    const totalTasks = task.projectTasks.length;
    const ongoingTasks = task.projectTasks.filter(task => task.tstatus === 0).length;
    const completedTasks = task.projectTasks.filter(task => task.tstatus === 1).length;
    setTotalTasksCount(totalTasks);
    setOngoingTasksCount(ongoingTasks);
    setCompletedTasksCount(completedTasks);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return pageReady && (
    <div className=" w-full dark:bg-dark-100  bg-stone-100 transition duration-300 h-auto lg:h-screen lg:mt-0  mt-20">
      <div className="container mx-auto">
        <HeaderComponent title={"Panel"} />
        <MenuBarComponent title={"prodash"} />
        <div className="flex lg:flex-row flex-col lg:space-x-5">
          <div className="lg:w-2/3  w-full">
            <PanelHpComponent
              projectData={ProjectsDashData.projectData}
              timelineData={ProjectsDashData.timelineData}
              usersData={ProjectsDashData.projectsUsers}
              isAdmin={ProjectsDashData.userstatus==11?true:false}
            />
          </div>
          <div className="lg:w-1/3 w-full ">
            <PAsideComponent
             total={totalTasksCount}
             current={ongoingTasksCount}
             finished={completedTasksCount} 
             
            />
            <PAside2Component
              title={"GÜNCEL SÜREÇ"}
              ddata={""}
              userimg={""}
            />
            <PAside2Component
              title={"MÜŞTERİ"}
              ddata={""}
              userdata={""}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
