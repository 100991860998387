import HeaderComponent from "mainComponents/headerComponent";
import MenuBarComponent from "../components/MenuBarComponent";
import React from "react";
import { PiCircleBold } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";

export default function ProjectsTimeline(props) {
  return (
    <div className=" w-full dark:bg-dark-100  bg-stone-100 transition duration-300 ">
      <div className="container mx-auto">
        <HeaderComponent title={"Proje Süreci"} />
        <MenuBarComponent title={"timeline"} />
        <div className="flex w-full mt-5">
          <ul className="timeline timeline-vertical w-full -ml-[500px]">
            <li>
              <div className="timeline-start">23 Mayıs 2024</div>
              <div className="timeline-middle">
                <div className="text-blue-600">
                  <PiCircleBold />
                </div>
              </div>
              <div className="timeline-end timeline-box rounded-md flex justify-between items-center min-h-20 gap-3 w-[1125px]">
                <div className="flex items-center gap-3">
                  <div className="text-red-600 ">
                    <FaCircle size={8} />
                  </div>
                  <div className="avatar">
                    <div className="w-10 rounded-full">
                      <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div>
                      <span className="font-bold">Emre</span>{" "}
                      <span className=" text-gray-500">Heypena projesine</span>{" "}
                      <span className="text-purple-500">
                        Yeni bir görev ekledi
                      </span>
                    </div>
                    <div className="text-xs text-gray-500">11:42 am</div>
                  </div>
                </div>
                {/* list */}
              </div>
              <hr />
            </li>
            <li>
              <hr />
              <div className="timeline-start">23 Mayıs 2024</div>
              <div className="timeline-middle">
                <div className="text-blue-600">
                  <PiCircleBold />
                </div>
              </div>
              <div className="timeline-end timeline-box rounded-md flex justify-between items-center min-h-20 gap-3 w-[1125px]">
                <div className="flex items-center gap-3">
                  <div className="text-red-600 ">
                    <FaCircle size={8} />
                  </div>
                  <div className="avatar">
                    <div className="w-10 rounded-full">
                      <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div>
                      <span className="font-bold">Emre</span>{" "}
                      <span className=" text-gray-500">Heypena projesine</span>{" "}
                      <span className="text-purple-500">
                        Yeni bir görev ekledi
                      </span>
                    </div>
                    <div className="text-xs text-gray-500">11:42 am</div>
                  </div>
                </div>
                {/* list */}
              </div>
              <hr />
            </li>
            <li>
              <hr />
              <div className="timeline-start">23 Mayıs 2024</div>
              <div className="timeline-middle">
                <div className="text-blue-600">
                  <PiCircleBold />
                </div>
              </div>
              <div className="timeline-end timeline-box rounded-md flex justify-between items-center min-h-20 gap-3 w-[1125px]">
                <div className="flex items-center gap-3">
                  <div className="text-red-600 ">
                    <FaCircle size={8} />
                  </div>
                  <div className="avatar">
                    <div className="w-10 rounded-full">
                      <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div>
                      <span className="font-bold">Emre</span>{" "}
                      <span className=" text-gray-500">Heypena projesine</span>{" "}
                      <span className="text-purple-500">
                        Yeni bir görev ekledi
                      </span>
                    </div>
                    <div className="text-xs text-gray-500">11:42 am</div>
                  </div>
                </div>
                {/* list */}
              </div>
              <hr />
            </li>
            <li>
              <hr />
              <div className="timeline-start">23 Mayıs 2024</div>
              <div className="timeline-middle">
                <div className="text-blue-600">
                  <PiCircleBold />
                </div>
              </div>
              <div className="timeline-end timeline-box rounded-md flex justify-between items-center min-h-20 gap-3 w-[1125px]">
                <div className="flex items-center gap-3">
                  <div className="text-red-600 ">
                    <FaCircle size={8} />
                  </div>
                  <div className="avatar">
                    <div className="w-10 rounded-full">
                      <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div>
                      <span className="font-bold">Emre</span>{" "}
                      <span className=" text-gray-500">Heypena projesine</span>{" "}
                      <span className="text-purple-500">
                        Yeni bir görev ekledi
                      </span>
                    </div>
                    <div className="text-xs text-gray-500">11:42 am</div>
                  </div>
                </div>
                {/* list */}
              </div>
              <hr />
            </li>
            <li>
              <hr />
              <div className="timeline-start">23 Mayıs 2024</div>
              <div className="timeline-middle">
                <div className="text-blue-600">
                  <PiCircleBold />
                </div>
              </div>
              <div className="timeline-end timeline-box rounded-md flex justify-between items-center min-h-20 gap-3 w-[1125px]">
                <div className="flex items-center gap-3">
                  <div className="text-red-600 ">
                    <FaCircle size={8} />
                  </div>
                  <div className="avatar">
                    <div className="w-10 rounded-full">
                      <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div>
                      <span className="font-bold">Emre</span>{" "}
                      <span className=" text-gray-500">Heypena projesine</span>{" "}
                      <span className="text-purple-500">
                        Yeni bir görev ekledi
                      </span>
                    </div>
                    <div className="text-xs text-gray-500">11:42 am</div>
                  </div>
                </div>
                {/* list */}
              </div>
              <hr />
            </li>
            <li>
              <hr />
              <div className="timeline-start">23 Mayıs 2024</div>
              <div className="timeline-middle">
                <div className="text-blue-600">
                  <PiCircleBold />
                </div>
              </div>
              <div className="timeline-end timeline-box rounded-md flex justify-between items-center min-h-20 gap-3 w-[1125px]">
                <div className="flex items-center gap-3">
                  <div className="text-red-600 ">
                    <FaCircle size={8} />
                  </div>
                  <div className="avatar">
                    <div className="w-10 rounded-full">
                      <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div>
                      <span className="font-bold">Emre</span>{" "}
                      <span className=" text-gray-500">Heypena projesine</span>{" "}
                      <span className="text-purple-500">
                        Yeni bir görev ekledi
                      </span>
                    </div>
                    <div className="text-xs text-gray-500">11:42 am</div>
                  </div>
                </div>
                {/* list */}
              </div>
              <hr />
            </li>

            <li>
              <hr />
              <div className="timeline-start">23 Mayıs 2024</div>
              <div className="timeline-middle">
                <div className="text-blue-600">
                  <PiCircleBold />
                </div>
              </div>
              <div className="timeline-end timeline-box rounded-md flex justify-between items-center min-h-20 gap-3 w-[1125px]">
                <div className="flex items-center gap-3">
                  <div className="text-red-600 ">
                    <FaCircle size={8} />
                  </div>
                  <div className="avatar">
                    <div className="w-10 rounded-full">
                      <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div>
                      <span className="font-bold">Emre</span>{" "}
                      <span className=" text-gray-500">Heypena projesine</span>{" "}
                      <span className="text-purple-500">
                        Yeni bir görev ekledi
                      </span>
                    </div>
                    <div className="text-xs text-gray-500">11:42 am</div>
                  </div>
                </div>
                {/* list */}
              </div>
              <hr />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
