import React, { useState, useEffect } from "react";

import { FiBriefcase } from "react-icons/fi";
// import { Badge, Avatar } from '@nextui-org/react' -EMRE

export default function WComeComponent({ userinfo, userstaskCount, isCheck }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const images = [
    // "/assets/penaLogos/blue.svg",
    // "/assets/penaLogos/yellow.svg",
    // "/assets/penaLogos/orange.svg",
    // "/assets/penaLogos/purple.svg",
    // "/assets/penaLogos/turquoise.svg",
    // "/assets/penaLogos/yellow.svg",
    "/assets/penaLogos/red.svg",
  
  ];
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    const randomImage = images[randomIndex];
    setSelectedImage(randomImage);
  }, []);
  return (
    <div className="md:flex md:flex-row flex ">
      <div className="border dark:border-dark-300 bg-white dark:bg-dark-300  w-full px-5 py-4">
        <div className="flex justify-between items-center md:flex-row flex-col  ">
          <div className="flex md:flex-row justify-center flex-col md:mb-0 mb-8 items-center gap-10">
            {/* <Badge content="" color="success" shape="circle" placement="bottom-right" className="w-6 h-6">
              <Avatar
                className="h-24 w-24"
                src={
                  isCheck ? userinfo?.uimg !== null ? process.env.NEXT_PUBLIC_USERIMG + userinfo.uimg + '.' + userinfo.uimgtype : process.env.NEXT_PUBLIC_USERIMG_BLANK : `pena`
                }
                alt="deneme"
              />
            </Badge> -EMRE */}

            <div className=" flex mx-auto items-center">
              {/* <div className="z-0 justify-center">
                <div className="avatar">
                  <div className="w-16 rounded-full">
                    <img
                      src={
                        isCheck
                          ? userinfo?.uimg !== null
                            ? process.env.NEXT_PUBLIC_USERIMG +
                              userinfo.uimg +
                              "." +
                              userinfo.uimgtype
                            : process.env.NEXT_PUBLIC_USERIMG_BLANK
                          : `pena`
                      }
                    />
                  </div>                 
                </div>
              </div> */}
              {selectedImage && (
                      <div className="">
                        <img
                          src={selectedImage}
                          alt="Selected"
                          className="w-20 h-20 rounded-full"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    )}
              <div className="z-30 w-2 h-2 justify-end rounded-full items-end bg-[#0ee20e]">
                &nbsp;&nbsp;
              </div>
            </div>
            <div className="flex flex-col ">
              <h1 className="m-4 mt-1 md:mb-0 mb-4 md:max-w-[120px] dark:text-darkWhite-300 text-[21px] font-semibold leading-7 text-[#283257]">
                {isCheck ? `Hoşgeldin, ${userinfo}` : `Yükleniyor`}
              </h1>
              <p className="opacity-50 md:ml-4 md:mb-0 mb-8 text-center text-sm">
                Seni burada görmek güzel.
              </p>
            </div>
            <div className="md:ml-10 md:dark:border-dark-100 md:border-l-2  flex flex-col lg:items-start items-center md:gap-0 md:pl-10 md:max-h-[100px]">
              <div className="text-[35px]  font-sans font-bold dark:text-darkWhite-300 text-[#283252]">
                {isCheck ? userstaskCount : "0"}
              </div>

              <p className="text-center text-sm text-[#A2A5B9]">
                Inceleme bekleyen görevler
              </p>

              <a
                href="/tasks"
                className="underline underline-offset-8 decoration-dotted  dark:text-darkPurple text-red-600 text-sm font-semibold"
              >
                Tüm Görevler
              </a>
            </div>
          </div>

          <div className=" min-h-full w-full md:w-1/4">
            <button className="text-white text-md text-left rounded-md dark:bg-darkViolet bg-[#da402f] shadow-md dark:shadow-darkViolet shadow-[#E7BCB7] p-2 w-full px-4">
              <p className="text-sm mt-3">
                Pena ile tüm projeleriniz sistematik <br />
                olsun.
              </p>
              <div className="flex flex-row  justify-between">
                <p className="text-sm self-center">Nasıl kullanırım?</p>

                <FiBriefcase className="text-[#E4796D] dark:text-darkPurple text-[56px] -mt-4" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
