import React from 'react'

export default function LandingMainA() {
  return (
    <div className="container mx-auto">
      <div className="flex lg:flex-row flex-col-reverse justify-between w-full ">
        <div className="text-[65px] font-bold text-[#3f3b3b] lg:max-w-[455px] w-full font-roboto leading-[70px] mt-8 mx-6">
          Her projenin bir
          <span className="text-[#e8420e]"> Pena</span>'ya <br /> ihtiyacı var.
          <div className="text-base text-gray-44 font-roboto font-normal max-w-md mt-4">
            Projeler oluşturun, görevler ekleyin, takviminizi planlayın ve takımınızla iletişim halinde olun. Dahası için hemen başlayın!
          </div>
        </div>

        <div className="flex md:flex-row flex-col space-x-6 items-center md:items-start">
          <div className="max-w-[250px]  rounded-2xl overflow-hidden shadow-lg">
            <img className="w-full max-h-[300px]" src="/assets/landing/homebg.png" alt="Pena Home BG 1" />
            <div className="px-6 py-4 bg-white">
              <p className="text-gray-700 lg:text-xl text-base">Projeni oluştur, ekibini kur ve süreci başlat!</p>
            </div>
          </div>

          <div className="max-w-[250px] rounded-2xl">
            <img className="w-full h-full" src="/assets/landing/penapho.png" alt="Pena Home BG 2" />
          </div>
        </div>
      </div>
    </div>
  )
}
