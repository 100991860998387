import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/(dashboard)/dashboard/page.js";
import DashboardLayout from "../pages/(dashboard)/layout";
import LandingPage from "../pages/landing/page";
import Login from "../pages/login/login";
import Register from "../pages/register/register.js";
import Projects from "pages/(dashboard)/projects/main/page.js";
import ProjectsDashboard from "pages/(dashboard)/projects/[id]/index";
import ProjectsTeam from "pages/(dashboard)/projects/[id]/team/page.js";
import ProjectsTasks from "pages/(dashboard)/projects/[id]/tasks/page.js";
import ProjectsTimeline from "pages/(dashboard)/projects/[id]/timeline/page.js";
import Create from "pages/(dashboard)/projects/create/page.js";
import Check from "pages/(dashboard)/projects/create/check/page.js";
import Finish from "pages/(dashboard)/projects/create/finish/page.js";
import Tasks from "pages/(dashboard)/tasks/page.js";
import Organization from '../pages/(dashboard)/organization/page.js'
import Calendar from "pages/(dashboard)/calendar/page.js";
import Payment from "pages/(dashboard)/payment/page.js";
import KanbanBoard from "pages/(dashboard)/projects/[id]/kanban/KanbanBoard.js";
import Planby from "pages/(dashboard)/planby/page.js";

function AppRouter() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route element={<DashboardLayout />}>
          {/* <Route path="planby" element={<Planby />} /> */}
          <Route path="dashboard" element={<Dashboard />} />
          {/* <Route path="calendar" element={<Calendar />} /> */}
          {/* <Route path="organization" element={<Organization />} /> */}
          <Route path="projects">
            <Route path="" element={<Projects />} />
            <Route path="create" element={<Create />} />
            <Route path="create/check" element={<Check />} />
            <Route path="create/finish" element={<Finish />} />
            <Route path=":id" element={<ProjectsDashboard />} />
            <Route path=":id/team" element={<ProjectsTeam />} />
            <Route path=":id/tasks" element={<ProjectsTasks />} />
            <Route path=":id/kanban" element={<KanbanBoard />} />

            <Route path=":id/timeline" element={<ProjectsTimeline />} />
            
          </Route>
          <Route path="tasks" element={<Tasks />} />
          {/* <Route path="payment" element={<Payment />} /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default AppRouter;
