import React from 'react'
import HeaderComponent from 'mainComponents/headerComponent'
import ButtonComponent from 'mainComponents/button'
import AcceptedComponent from './components/acceptedComponent'

export default function Finish() {
   return (
    <div className=" w-full dark:bg-dark-100  bg-stone-100 min-h-screen">
      <div className="container mx-auto">
        <HeaderComponent title={'Harika! Proje Oluşturuldu.'} />
        <AcceptedComponent />
        <div className="flex justify-center ">
          <ButtonComponent
            id=""
            href="/projects"
            title="Projeyi Görüntüle"
            bgcolor="h-[28px] p-1 shadow-violet-300 bg-red-600 rounded-3xl text-white w-40 dark:bg-violet-500 hover:bg-violet-600"
          />
        </div>
      </div>
    </div>
  )
}


