import React from 'react'

export default function LandingFooter() {
  return (
    <div className="container mx-auto mt-44">
      <div className="flex lg:flex-row flex-col justify-between lg:gap-0 gap-5">
        <div className="lg:max-w-sm max-w-max">
          Hey Pena, projelerini geliştirirken süreç takibinde sorun yaşayan ekipler ve güncellemeleri takip etmek isteyen herkes için geliştirildi.
          <div className="mt-5">Created by brave co.</div>
        </div>
        <ul className="flex lg:flex-row flex-col lg:gap-4 gap-32 text-gray-33 font-semibold mb-32">
          <li className="w-[170px] h-[32px] text-xl">
            PENA
            <ul className="mt-5">
              <li className="font-thin">Giriş</li>
              <li className="font-thin">Özellikler</li>
              <li className="font-thin">Araçlar</li>
            </ul>
          </li>
          <li className="w-[170px] h-[32px] text-xl">
            YOLHARİTASI
            <ul className="mt-5">
              <li className="font-thin">Giriş Yap</li>
              <li className="font-thin">Kayıt Ol</li>
              <li className="font-thin">Nasıl Kullanırım?</li>
              <li className="font-thin">İletişim</li>
            </ul>
          </li>
          <li className="w-[170px] h-[32px] text-xl">
            EKSTRA
            <ul className="mt-5">
              <li className="font-thin">KVKK Metni</li>
              <li className="font-thin">Gizlilik Sözleşmesi</li>
              <li className="font-thin">Cookie Kullanımı</li>
            </ul>
          </li>
        </ul>
      </div>
      <p className="text-zinc-400 text-center mt-16 text-sm"> @2024 | Hey Pena, All rights reserved </p>
      <br></br>
      <br></br>
      <br></br>
    </div>
  )
}
