import React from 'react'
import SearchComponent from './Sidebar/Search/index'
import RequestPopupComponent from './Sidebar/RequestPopup/index'
import ThemePopupComponent from './Sidebar/ThemePopup/index'
import EditPopupComponent from './Sidebar/EditPopup/index'
import DeletePopupComponent from './Sidebar/deletepopup/index'
import TaskComponent from './Sidebar/Task/index'
import ProjectTaskComponent from './Sidebar/ProjectTask/index'
import TaskDatailComponent from './Sidebar/TaskDatail/index'
import TaskCardSComponent from './Sidebar/TaskCardS/index'
import { useSelector } from 'react-redux'

export default function Popups() {
  const active = useSelector((state) => state.popupState.activePanel)

  return (
    <React.Fragment>
      {active[0] == 9 && <SearchComponent />}
      {active[0] == 1 && <RequestPopupComponent />}
      {active[0] == 2 && <ThemePopupComponent />}
      {active[0] == 3 && <EditPopupComponent />}
      {active[0] == 4 && <DeletePopupComponent />}
      {active[0] == 5 && <TaskComponent type={active[1]} />}
      {active[0] == 6 && <ProjectTaskComponent type={active[1]} />}
      {active[0] == 7 && <TaskDatailComponent />}
      {active[0] == 8 && <TaskCardSComponent />}
      
    </React.Fragment>
  )
}
