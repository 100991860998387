import React from 'react'

export default function LandingMainTwo() {
  return (
    <div className="container mx-auto">
      <div className="flex justify-center items-start">
        <div className="flex flex-col ">
          <div className="lg:self-center self-center font-bold text-[30px] md:text-[40px] text-gray-33 ">Proje Yönetim Sistemi</div>
          <div className="lg:self-center self-center lg:text-center lg:text-[20px] text-sm font-semibold text-gray-33 lg:max-w-2xl max-w-md">
            Hey Pena, projelerini geliştirirken süreç takibinde sorun yaşayan ekipler ve güncellemeleri takip etmek isteyen herkes için geliştirildi.
          </div>
          <div>
            <img src="../../assets/landing/penakomp.png" alt="LandingMainTwo" className="lg:max-w-[646px] max-w-md mt-5" />
          </div>
        </div>
      </div>
    </div>
  )
}
