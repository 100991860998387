import HeaderComponent from "mainComponents/headerComponent.js";
import { getMytaskList } from "./api/mytask";
import React, { useEffect, useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setActivePanel } from "store/popupState";
import { FiCircle, FiPlus } from "react-icons/fi";
import PriorityList from "constants";
import GorevlerCardComponent from "./components/GorevlerCardComponent/index";

export default function Tasks() {
  const [taskData, setTaskData] = useState([]);
  const [handleClick, setHandleClick] = useState(true);
  const dispatch = useDispatch();
  const animals = PriorityList();
  const status = ["Yeni", "İşlemde", "Hazır", "İncelemede", "Tamamlandı"];

  async function fetchData() {
    const data = await getMytaskList();
    setTaskData(data.usersTasksData)
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className={`w-full dark:bg-dark-100  bg-stone-100  transition duration-300  lg:mt-0 mt-20 lg:h-auto h-auto`}
    >
      <div className=" container mx-auto h-auto">
        <HeaderComponent title="Görevler" />

        <div className="w-full flex justify-center items-center mt-6">
          <div className="w-full justify-center items-center">
            <div className="flex justify-end">
              <div className="flex space-x-3 pr-4">
                <button
                  onClick={() => dispatch(setActivePanel([6, 0]))}
                  className="btn rounded-md text-white text-sm min-w-[120px] h-[45px] dark:bg-darkViolet bg-[#da402f] shadow-md"
                >
                  <div className="flex items-end gap-1 text-sm font-semibold">
                    <FiPlus size={18} /> <span>Yeni Görev</span>
                  </div>
                </button>
                <button
                  className="h-[45px] btn rounded-md text-black text-xs dark:bg-dark-500 bg-white"
                  onClick={() => {
                    setHandleClick(!handleClick);
                  }}
                >
                  <AiOutlineUnorderedList className="text-[18px] mx-auto dark:text-darkWhite-300 " />
                </button>
                <div className="min-w-[200px] h-[45px]">
                  <select className="select w-full max-w-xs">
                    <option startContent={<FiCircle />} disabled selected>
                      Öncelik seçiniz
                    </option>

                    {animals.map((animal) => (
                      <option key={animal.value} value={animal.value}>
                        {animal.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {taskData ? (
              <div
                className={`grid mt-6 gap-3 ${
                  handleClick ? "lg:grid-cols-3 grid-cols-1 " : "grid-cols-1"
                }`}
              >
                {taskData.map((task, index) => (
                  <GorevlerCardComponent
                    key={index}
                    title={task.tsubject}
                    date={task.created_at}
                    priority={task.tpriority}
                    isPrivate={false}
                    status={status[task.tstatus]}
                    member={{
                      member_name: `${task.name} ${task.ulname}`,
                      member_img: task.uimg,
                      member_imgtype: task.uimgtype,
                    }}
                    onClick={() => dispatch(setActivePanel([5, task.id]))}
                  />
                ))}
              </div>
            ) : (
              <div className="flex justify-end items-center flex-col">
                <div className="grid place-items-center mt-4 ">
                  <div className="max-w-[340px] w-full h-auto">
                    {/* <Image src={Tasklight} className="dark:hidden" alt="" />
                <Image src={Taskdark} className="hidden dark:block" alt="" /> */}
                  </div>

                  <div className="text-xl my-5 font-semibold text-gray-39 dark:text-darkWhite-300">
                    Henüz hiç görev eklenmemiş.
                  </div>

                  <div className="mb-[110px] -my-4 text-center text-md text-gray-39 dark:text-darkWhite-300 font-medium opacity-50 max-w-[620px] ">
                    Bu sayfa sadece sizin görebileceğiniz görevleri eklemenize
                    yardımcı olur. Yeni görev eklemek için sağ üst köşedeki Yeni
                    Görev butonunu kullanabilir ve öncelik durumuna göre
                    görevlerinizi ekleyebilirsiniz.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
