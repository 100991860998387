import React from 'react'
import ButtonComponent from 'mainComponents/button'
import { useDispatch,useSelector } from 'react-redux'
import { setProjectDetails } from 'store/createProjectState'


export function ProCreateComponent() {
  const dispatch = useDispatch()
  const { projectDetails } = useSelector((state) => state.createProjectState)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setProjectDetails({[name]: value}))
  }

  return (
    <div className="flex justify-center items-center">
      <div className="flex-col my-4">
        <div className="flex justify-center text-[20px] font-bold text-gray-39 dark:text-darkWhite-300">Projeniz ne hakkında?</div>
        <div className="flex justify-center text-l font-light opacity-50 text-gray-39 dark:text-darkWhite-300">Tüm proje bilgilerini ekleyerek daha iyi bir yönetim sağlayın.</div>
        <div className="flex space-y-1 flex-col my-5">
          <div className="flex justify-center items-center w-full py-3 border-1 rounded-2xl bg-white dark:bg-dark-500 mb-4 dark:border-dark-500 ">
            <input
              className="flex w-full text-center px-3 justify-center items-center outline-none border-none opacity-70 border-2 rounded-2xl dark:bg-dark-500"
              type="text"
              name="pname"
              value={projectDetails.pname}
              onChange={handleInputChange}
              maxLength={50}
              placeholder="Proje Adı"
            />
          </div>
          <div className="flex-col flex w-[500px] h-[120px] border-1 rounded-2xl bg-white dark:bg-dark-500 mb-4 dark:border-dark-500 ">
            <input
              className="pl-2 mt-2 outline-none border-none opacity-70 border-2 rounded-2xl dark:bg-dark-500"
              type="text"
              name="pdesc"
              value={projectDetails.pdesc}
              onChange={handleInputChange}
              placeholder="Projenizi tanımlayın..."
            />
          </div>
          <p className="text-[10px] opacity-60 font-light text-[#a2a5b9]  dark:text-gray-100 ml-1">Maximum 150 karakter</p>
        </div>

        <p className="text-[13px] opacity-60 text-gray-950 font-semibold dark:text-gray-100 ml-1">Projenizin ait olduğu firma adı</p>
        <div className="flex w-[500px] h-[60px] justify-center items-center border-2 rounded-2xl bg-white dark:bg-dark-500 mb-4 dark:border-dark-500">
          <input
            className="flex w-full text-center px-3 justify-center items-center outline-none border-none opacity-70 border-1 rounded-2xl dark:bg-dark-500"
            type="text"
            name="pfirm"
            value={projectDetails.pfirm}
            onChange={handleInputChange}
            maxLength={50}
            placeholder="Firma Adı"
          />
        </div>

        <div className="flex justify-center space-x-2 bg-white w-80 mx-auto p-4 rounded-2xl dark:bg-dark-500">
          <ButtonComponent
            href="/projects"
            title={'Geri'}
            bgcolor="bg-gray-100 text-black rounded-xl  w-44 dark:bg-dark-300 dark:text-gray-300 hover:bg-stone-200  border-2 dark:border-dark-500 dark:hover:border-violet-500"
          />
          <ButtonComponent
            href="/projects/create/check "
            title={'İleri'}
            bgcolor="bg-red-600 rounded-xl text-white w-44 dark:bg-violet-500 hover:bg-violet-500"
          />
        </div>
      </div>
    </div>
  )
}
export default ProCreateComponent
