import React, { useState } from 'react'
import { postProjectsUserDelete, getProjectsTeam } from '../../../services/projects'
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

function DeletePopupComponent(props) {
  const [isOpen, setIsOpen] = useState(true)
  const [single, setSingle] = useState([]);
  const navigate = useNavigate();
  
  const path = useParams();

  useEffect(() => {
    if (path.id !== undefined) {
      getTeam(path.id);
    }
  }, [path.id]);
  async function getTeam(id) {
  const data = { slug: id };
  const team = await getProjectsTeam(data);
  setSingle(team.currentUser.user_id);
}

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      // Eğer overlay alanına tıklanırsa kapat
      setIsOpen(false)
    }
  }

  const handleNoButtonClick = () => {
    setIsOpen(false)
  }
  const handleYesButtonClick = () => {
    delProject()
    setIsOpen(false)
    navigate("/projects");

  }

  async function delProject() {
      const data = {  slug: path.id ,
     
        id:single
     };
    console.log(data,"delProject")
    const set = await postProjectsUserDelete(data)
   setIsOpen(false)
 }
// 403 hatası -emre

  
  // const delProject = async () => {
  //   try {
  //     console.log("try")
  //     const response = await postProjectsUserDelete();
  //     if (response && response.slug) {
  //     console.log("response")
  //     } else {
  //       console.log("else")

  //     }
  //     setIsOpen(false);
  //   } catch (error) {
      
  //   }
  // }

 
  return (
    <>
      {isOpen && (
        <div className="z-50 absolute inset-0 flex justify-center items-center" onClick={handleBackgroundClick}>
          <div className="bg-black opacity-50 fixed inset-0 cursor-pointer"></div>
          <div className="bg-white w-[300px] h-[170px] rounded-3xl relative z-50">
            <div className="flex justify-center items-center">
              <h1 className="p-2 font-bold text-green-400">Projeden Ayrıl</h1>
            </div>
            <div className="w-full border-t border-gray-300"></div>
            <div className="p-4">
              <p className="text-sm text-center mb-4">Bu projeden ayrılmak istediğinize emin misiniz?</p>
              <div className="flex justify-center space-x-2">
                <button className=" btn bg-green-400 font-bold text-md text-white" color="secondary" onClick={handleYesButtonClick}>
                  Evet, Ayrıl
                </button>
                <button className=" btn bg-gray-400 font-bold text-md text-white" color="secondary" onClick={handleNoButtonClick}>
                  Hayır, Kalsın
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DeletePopupComponent
