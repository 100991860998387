import React from "react";
import { FaTimes } from "react-icons/fa";
import { useState } from "react";

const EditPopupComponent = ({ projectsData, closePopup, onUpdate, show }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    closePopup(false);
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      closePopup(false);
    }
  };

  return (
    <>
      {show && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 "
          onClick={handleBackgroundClick}
        >
          <div className="bg-white w-[800px] h-[500px] rounded-md z-50 overflow-y-auto overflow-x-hidden">
            <div className="space-y-4">
              <div className=" p-4 ">
                <h1 className="font-bold text-gray-39 text-sm">
                  Projeyi Düzenle
                </h1>
              </div>

              <div className="w-full mt-4 border-t border-zinc-300"></div>

              <div className="flex p-4">
                <h4 className="text-sm opacity-60 w-2/4 mt-8 ">
                  Güncel Görsel
                </h4>
                <div className=" text-gray-39 w-4/6 text-sm mt-4 border-dashed  border-stone-300 rounded-md border-[3px] ">
                  <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                    {projectsData.pimg !== null ? (
                      <div
                        className="avatar border-[1px]"
                        src={
                          process.env.NEXT_PUBLIC_USERIMG +
                          projectsData.pimg +
                          "." +
                          projectsData.pimgtype
                        }
                      />
                    ) : (
                      <input
                        type="text"
                        placeholder="Type here"
                        className=" h-[80px] bg-stone-200 input input-bordered w-full"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="p-4 space-y-4 text-gray-39 rounded-lg">
                <h4 className="text-sm opacity-60">Proje Adı</h4>
                <div className=" ">
                  <input
                    className="w-[750px] p-2 h-[40px] text-sm"
                    type="text"
                    placeholder="Type here"
                    defaultValue={projectsData.pname}
                  />
                </div>

                <div className="flex gap-3 w-2/4 ">
                  <div>
                    <h4 className="text-sm opacity-60 ">Proje Firması</h4>
                    <div className="flex  flex-wrap md:flex-nowrap gap-4">
                      <input
                        type="text"
                        className="p-2 my-2"
                        placeholder="Type here"
                        defaultValue={projectsData.pfirm}
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="text-sm opacity-60">Proje Müşterisi</h4>
                    <div className="flex  flex-wrap md:flex-nowrap gap-4">
                      <input
                        type="text"
                        className="p-2 my-2"
                        placeholder="Type here"
                        defaultValue={projectsData.pcustomer}
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="text-sm opacity-60">Tahmini Bitiş Tarihi</h4>
                    <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                      <input
                        type="text"
                        className="p-2 my-2"
                        placeholder="Type here"
                        defaultValue={projectsData.penddate}
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="text-sm opacity-60">Proje Alanı</h4>
                    <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                      <input className="p-2 my-2" type="text" label="" />
                    </div>
                  </div>
                </div>

                 
              

                <div>
                  <h4 className="text-sm opacity-60">Proje Bütçesi</h4>
                  <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                    <input
                      className="p-2 my-2"
                      type="text"
                      placeholder="Type here"
                      defaultValue={projectsData.pbudget}
                    />
                  </div>
                </div>
              </div>
              <div className="p-4">
                <h4 className="text-sm opacity-60">Açıklama</h4>
                <div className="flex flex-wrap md:flex-nowrap gap-4">
                  <input
                    type="text"
                    className="p-2 my-2"
                    placeholder="Type here"
                    defaultValue={projectsData.pdesc}
                  />
                </div>
              </div>
              <div className="space-y-4 mt-4 rounded-lg p-4 ">
                <div className="w-full h-[140px] bg-cyan-50 border-2 border-cyan-300 p-6">
                  <p className="opacity-50 text-md">
                    Projeyi tamamlandı olarak işaretlediğiniz zaman hiçbir
                    işlemi kullanamayacaksınız. Tekrardan aktif edene kadar tüm
                    işlemler kısıtlanacaktır.
                  </p>
                  <div>
                    <button
                      className="btn border-[#039be5] mt-2 text-[#039be5] hover:bg-cyan-300"
                      color="primary"
                      variant="ghost"
                    >
                      <p> Tamamlandı olarak işaretle!</p>
                      <FaTimes size={18} />
                    </button>
                  </div>
                </div>

                <div className="w-full h-[140px] bg-orange-50 border-2 border-orange-300 p-6">
                  <p className="opacity-50 text-md">
                    Projeyi deaktif ettiğiniz zaman hiçbir işlemi
                    kullanamayacaksınız. Tekrardan aktif edene kadar tüm
                    işlemler kısıtlanacaktır.
                  </p>
                  <div className="">
                    <button
                      className="btn border-[#faae42] mt-2 text-[#faae42] hover:bg-orange-300"
                      color="primary"
                      variant="ghost"
                    >
                      <p>Deaktif et</p>
                      <FaTimes size={18} />
                    </button>
                  </div>
                </div>

                <div className="w-full h-[160px] bg-rose-50 border-2 border-rose-300 p-6">
                  <h3 className="text-red-600 font-bold">DİKKAT!</h3>
                  <p className="opacity-50 text-md">
                    Proje silme işlemini onaylamanız halinde, proje içerisinde
                    bulunan tüm içerikler, kullanıcılar, görevler ve kayıtlı
                    olan tüm dökümanlar kalıcı olarak silinmektedir.
                  </p>
                  <div className="">
                    <button
                      className="btn border-[#e62965] mt-2 text-[#e62965] border-1 hover:bg-rose-300"
                      color="primary"
                      variant="ghost"
                    >
                      <p> Kalıcı olarak sil</p>
                      <FaTimes size={18} />
                    </button>
                  </div>
                </div>
              </div>

              <div className="w-full border-t border-zinc-300"></div>
              <div className="flex justify-end m-2 ">
                <button
                  className="btn text-black"
                  color="primary"
                  variant="ghost"
                >
                  Çıkış
                </button>
                <button className="btn ml-2" color="danger">
                  Güncelle
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditPopupComponent;
