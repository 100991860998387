import React from "react";
import { Link } from "react-router-dom";

const ButtonComponent = (props) => {
  return (
    <Link to={props.href ? props.href : "#"}>
      <div
        className={`${props.icon ? "flex" : ""} w-12 h-10 p-4 items-center ${
          props.bgcolor
        }`}
        onClick={props.onClick}
      >
        {props.icon} {props.title}
      </div>
    </Link>
  );
};

export default ButtonComponent;
